<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{ $t("suppliers.mainDialog.edit.title") }}</h3>
        <h3 v-else>{{ $t("suppliers.mainDialog.add.title") }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <!-- Name -->
            <v-col cols="12" class="mt-12">
              <v-text-field
                outlined
                :rules="requiredRules"
                :label="$t('suppliers.labels.name')"
                maxlength="100"
                hide-details
                v-model="form.name"
              />
            </v-col>

            <!-- Type -->
            <v-col cols="12">
              <SelectGeneric
                :items="$t('constants.supplierTypes.list')"
                :label="$t('suppliers.labels.type')"
                :value="form.type"
                @input="onInputType"
              />
            </v-col>

            <!-- FM Referent -->
            <v-col cols="12">
              <SelectFMReferent
                :is-multiple="true"
                @input="onInputFMReferents"
                :value="form.fm_referents"
              />
            </v-col>

            <!-- Group -->
            <v-col cols="12" v-if="form.type !== 'GROUP'">
              <SelectSupplier
                :value="form.parent"
                :label="$t('suppliers.labels.parent')"
                supplier-type="GROUP"
                @input="onInputParent"
              />
            </v-col>

            <!-- Category -->
            <v-col cols="12">
              <SelectGeneric
                :return-object="false"
                :is-required="false"
                :slot-item="{ text: 'value', value: 'value' }"
                :items="$t('constants.supplierCategories.list')"
                :value="form.category"
                :label="$t('suppliers.labels.category')"
                @input="onInputCategory"
              />
            </v-col>

            <!-- Subcategories -->
            <v-col cols="12">
              <SelectGeneric
                :is-multiple="true"
                :return-object="false"
                :slot-item="{ text: 'value', value: 'value' }"
                :items="$t('constants.supplierCategories.list')"
                :value="form.subcategories"
                :label="$t('suppliers.labels.subcategories')"
                @input="onInputSubcategories"
              />
            </v-col>

            <!-- Account details : Website -->
            <v-col cols="12">
              <v-text-field
                outlined
                :label="$t('suppliers.labels.website')"
                maxlength="256"
                hide-details
                v-model="form.details.website"
              />
            </v-col>

            <!-- Account details : nb employees -->
            <v-col cols="12">
              <v-text-field
                outlined
                :label="$t('suppliers.labels.nb_employees')"
                hide-details
                type="number"
                v-model="form.details.nb_employees"
              />
            </v-col>

            <!-- Address -->
            <v-col cols="12">
              <div class="subtitle-2 font-weight-bold">
                {{ $t("suppliers.labels.address") }}
              </div>
              <AddressForm v-if="form.address" :form="form.address" />
            </v-col>

            <!-- Can edit forms -->
            <v-col cols="12">
              <v-switch
                :label="$t('suppliers.labels.canEditForms')"
                v-model="form.canEditForms"
              />
            </v-col>

            <!-- Compliance grade -->
            <v-col cols="12">
              <v-slider
                :label="$t('suppliers.labels.complianceGrade')"
                v-model="form.complianceGrade"
                step="0.01"
                tick-size="2"
                min="0"
                max="1"
                hide-details
              />
              <div>{{ form.complianceGrade * 100 }}%</div>
            </v-col>

            <v-col cols="12">
              <AlertBar />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-3"
          :is-loading-btn-save="isLoadingBtn.btnSave"
          @submit="customSubmit"
          @cancel="close"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog";
import formRules from "@/mixins/formRules";

export default {
  name: "SupplierMainDialog",

  mixins: [dialogMixin, formRules],

  components: {
    AddressForm: () => import("@/components/Common/Forms/AddressForm"),
    SelectSupplier: () => import("@/components/Common/Inputs/SelectSupplier"),
    SelectGeneric: () => import("@/components/Common/Inputs/SelectGeneric"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () =>
      import("@/components/Common/Buttons/ButtonBarFormDialog"),
    SelectFMReferent: () =>
      import("@/components/Common/Inputs/SelectFMReferent"),
  },

  data() {
    return {
      isLoadingBtn: { btnSave: false },

      defaultForm: {
        name: "",
        type: "FACTORY",
        parent: null,
        category: "OTHER",
        fm_referents: [
          { id: this.$session.get("id"), person: this.$session.get("person") },
        ],
        subcategories: [],
        address: {},
        details: {},
        factory_performances: {},
        canEditForms: true,
        complianceGrade: 0,
      },
    };
  },

  created() {
    if (this.item && !this.item.address) this.form.address = {};
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.shortcutFormObjects(["fm_referents", "parent"]);
        this.shortcutFormObjects(["type"], "value");
        this.shortcutFormObjects(["subcategories"], "value");
        this.form.complianceGrade = Number(this.form.complianceGrade);
        if (this.item) this.submitEdit();
        else this.submitAdd();
      }
    },

    submitAdd() {
      this.$http
        .post(`/suppliers`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("suppliers.mainDialog.add.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    submitEdit() {
      this.$http
        .put(`/suppliers/${this.item.id}`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("suppliers.mainDialog.edit.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    onInputType(val) {
      this.form.type = val;
    },

    onInputParent(val) {
      this.form.parent = val;
    },

    onInputCategory(val) {
      this.form.category = val;
    },

    onInputSubcategories(val) {
      this.form.subcategories = val;
    },

    onInputFMReferents(val) {
      this.form.fm_referents = val;
    },
  },
};
</script>

<style scoped></style>
